import React, { useState, useEffect } from 'react'
import MembersView from './members.view'
import { QrCodeView } from './qrcode.view'
import RemoveMembersLinks from './member.remove'

export function SessionPresenter({ session, onRemoveMember, resetAllVotes }) {
  const [showQrCode, setShowQrCode] = useState(false)

  useEffect(() => {
    console.debug('session in Session Presenter is:', session)
    document.title = `${session.id} ${'Planning for the win'} - Vote App`
  }, [session])

  const removeSession = async () => {
    console.debug('removing session')
  }

  return (
    <div className="h-full flex-grow">
      <div
        className="flex justify-center m-3 text-base sm:text-base md:text-xl lg:text-2xl xl:text-3xl capitalize"
        style={{ fontFamily: `'Roboto Condensed', sans-serif;"` }}
      >
        {session.name}
      </div>

      <div className="flex text-xs justify-center m-6 select-none">
        <div
          className="flex mb-2 text-sm"
          style={{ fontFamily: `'Space Mono', monospace` }}
        >
          <div className="px-2 py-2">
            Session <span className="font-bold select-all">{session.id}</span>:
          </div>

          <button
            onClick={(e) => setShowQrCode(!showQrCode)}
            className="px-4 py-2 cursor-pointer underline hover:no-underline select-none"
          >
            {!showQrCode ? 'See QR Code' : 'Hide QR Code'}
          </button>
          <button
            onClick={async (e) => {
              e.preventDefault()
              await resetAllVotes()
            }}
            className="px-4 py-2 cursor-pointer underline hover:no-underline select-none"
          >
            Reset
          </button>
          <button
            onClick={async (e) => {
              e.preventDefault()
              await removeSession()
            }}
            className="px-4 py-2 cursor-pointer underline hover:no-underline select-none"
          >
            Exit
          </button>
        </div>
      </div>

      <div className="flex text-xs justify-center m-4 select-none">
        {showQrCode ? <QrCodeView id={session.id} /> : ''}
      </div>

      <MembersView members={session.members} status={session.status} />

      <RemoveMembersLinks
        className="my-4"
        members={session.members || []}
        onRemoveMember={onRemoveMember}
      />
    </div>
  )
}
