import React, { useContext, useLayoutEffect, useState, useEffect } from 'react'
import { AppMessageRenderer } from './app.messgae.renderer'
import { ThemeSwitcher } from './theme.switcher'
import { ThemeContext } from '../context/theme.context'
import { Heart } from 'react-feather'

const getOtherThemeName = (thiz) =>
  thiz === 'mode-white' ? 'mode-dark' : 'mode-white'

export const Layout = (props) => {
  // eslint-disable-next-line
  const { theme, _ } = useContext(ThemeContext)
  const [heartClass, setHeartClass] = useState('stroke-current')
  useEffect(() => {
    const interval = setInterval(() => {
      setHeartClass(
        heartClass === 'stroke-current' ? 'fill-current' : 'stroke-current'
      )
    }, 1000)
    return () => clearInterval(interval)
  }, [heartClass])
  useLayoutEffect(() => {
    if (document && document.body && document.body && theme) {
      document.body.classList.remove(getOtherThemeName(theme))
      document.body.classList.add(theme)
    }
  }, [theme])

  return (
    <div className="z-10 bg-white dark:bg-gray-900 text-black dark:text-gray-300 antialiased text-md flex-grow flex flex-col min-h-screen w-full select-none border-gray-300 dark:border-gray-700">
      <AppMessageRenderer />
      {props.children}
      <div className="flex justify-center flex-row border-t-0 space-x-2 text-xs md:text-base lg:text-base">
        <div className="pt-2">The Sprint Planner App</div>
        <ThemeSwitcher />
        <div className="pt-2 flex flex-row content-center space-x-2">
          <span className="">Made with </span>
          <Heart className={`m-1 w-4 h-4 text-green-500 ${heartClass}`} />
          <span>by Afrim</span>
        </div>
      </div>
    </div>
  )
}
