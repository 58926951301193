import React, { useEffect, useState, useContext } from 'react'
import { StartLayout } from '../components/start.layout'
import { SessionVote } from '../components/session.vote'
import { withSession } from '../api'
import { useNavigate } from '@reach/router'
import { AppContext } from '../context/app.context'
import { Loading } from '../components/loading'

export const Votes = ({ id, memberId }) => {
  const app = useContext(AppContext)
  const navigate = useNavigate()

  const [session, setSession] = useState(null)
  const [member, setMember] = useState(null)

  let unRegister
  const onMemberRemoved = (id) => {
    if (id === memberId) {
      setMember(null)
      app.setMessage('You have been removed.')
      navigate('/')
    }
  }

  const onSessionUpdate = (session) => {
    if (session && session.members) {
      const memberToFind = session.members.filter(
        (member) => member.id === memberId
      )
      if (!memberToFind && memberToFind.length < 1) {
        app.setMessage("Couldn't find you. Join again.")
        navigate('/')
      }
      setMember(memberToFind[0])
    }
    setSession(session)
  }

  useEffect(() => {
    document.title = `Voting - Sprint Planner`
    withSession(id, onSessionUpdate, onMemberRemoved)
      .then((data) => {
        onSessionUpdate(data.session)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        unRegister = data.unRegister
      })
      .catch((e) => {
        app.setMessage(e.message)
        navigate('/')
      })

    return function cleanup() {
      if (unRegister) {
        console.debug(`cleaning up on Votes.`)
        unRegister()
        unRegister = null
      }
    }
  }, [id, memberId])

  if (session) {
    return (
      <StartLayout>
        <SessionVote
          sessionId={id}
          memberId={memberId}
          member={member}
          session={session}
        />
      </StartLayout>
    )
  } else {
    return <Loading />
  }
}
