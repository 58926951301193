/* eslint-disable no-unreachable */
import React, { useContext, useEffect } from 'react'
import { StartLayout } from '../components/start.layout'
import { CreateSessionView } from '../components/create.session.view'
import { createSession, getSessionIdById } from '../api'
import { navigate } from '@reach/router'
import { AppContext } from '../context/app.context'
import { event } from '../analytics'
import { useAuth } from '../context/useAuth'

export const Create = () => {
  const app = useContext(AppContext)
  const { user, signinAnonymously } = useAuth()

  useEffect(() => {
    if (!user) {
      signinAnonymously()
    }
    document.title = 'Create Session - Sprint Planner'
    event('create_session')
  }, [user, signinAnonymously])

  const onCreateSession = async (name, type) => {
    try {
      if (!name || !type) {
        event('create_session_fail')
        throw new Error('Session name and type is required.')
      }
      const id = await createSession(name, type, 'new', user.uid)
      event('create_session_done', { sessionId: id, type })

      const sessionId = await getSessionIdById(id)
      console.debug(sessionId)
      navigate(`${sessionId}`)
    } catch (e) {
      app.setMessage(e.message)
      console.error(`Could not create session. ${e.message}`)
    }
  }

  return (
    <StartLayout>
      <CreateSessionView
        onCreateSession={onCreateSession}
        defaultVoteTypes={['1', '2', '3', '5', '8', '13', '21', '∞']}
      />
    </StartLayout>
  )
}
