import React from 'react'
import QRCode from 'qrcode.react'
import { useLocation } from '@reach/router'

export const QrCodeView = ({ id }) => {
  const location = useLocation()
  return (
    <QRCode
      height={200}
      width={200}
      fgColor="#fed7e2"
      bgColor="#000000"
      className="border-4 border-purple-600 rounded-lg"
      value={location.origin + `/join/${id}`}
      renderAs="svg"
    />
  )
}
