export const firebaseConfig = {
  apiKey: "AIzaSyCgfEgQ26qRnGNxmEV6i-c2yRNDVmkIyGo",
  authDomain: "afrimdev-votes-int.firebaseapp.com",
  databaseURL: "https://afrimdev-votes-int.firebaseio.com",
  projectId: "afrimdev-votes-int",
  storageBucket: "afrimdev-votes-int.appspot.com",
  messagingSenderId: "877925144466",
  appId: "1:877925144466:web:0cca8b71386f10fd39934b",
  measurementId: "G-L7V35EB7EV"
};
