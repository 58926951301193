import * as firebase from 'firebase/app'
import 'firebase/firestore'
import { find, remove, filter } from 'lodash'
import { firebaseConfig } from './firebaseconfig'

if (!firebase.apps || firebase.apps.length === 0)
  firebase.initializeApp(firebaseConfig)

export const db = () => {
  return firebase.firestore()
}

export const createSession = async (
  name,
  voteTypes,
  status = 'new',
  ownerId = null
) => {
  let randomString = Math.random().toString(36)
  const id = randomString.substring(
    randomString.length - 7,
    randomString.length
  )
  const newSessionRef = db().collection('sessions').doc()
  await newSessionRef.set({
    id,
    name,
    status,
    votingOptions: voteTypes,
    ownerId: ownerId,
  })
  console.debug(`added a new db. id is: ${id}, ref is: ${newSessionRef.id}`)
  return id
}

export const addMember = async (sessionId, user) => {
  const userRef = db()
    .collection(`sessions`)
    .doc(sessionId)
    .collection(`members`)
    .doc()
  await userRef.set(user)

  return userRef.id
}

export const withSession = async (id, onSessionChange, onMemberRemoved) => {
  if (!id && (id.length !== 7 || id.length !== 20)) {
    throw Error('Invalid session ID.')
  }

  let sessionId

  if (id.length === 20) {
    sessionId = id
  } else if (id.length === 7) {
    sessionId = await getSessionIdById(id)
  }

  const sessionRef = db().collection('sessions').doc(sessionId)

  //get the session
  const sessionDoc = await sessionRef.get()
  let session = { ...sessionDoc.data(), sessionId }
  //get the members
  const members = (await sessionRef.collection('members').get()).docs.map(
    (d) => {
      return { ...d.data(), id: d.id }
    }
  )

  const onSession = sessionRef.onSnapshot({}, (doc) => {
    console.debug(`session status changed ${doc.data().status}`)
    session.status = doc.data().status
    onSessionChange({ ...session, status: doc.data().status })
  })

  const onMembers = sessionRef.collection('members').onSnapshot((doc) => {
    console.debug(`members changed...`)
    console.debug(doc.id)
    let members = session.members || []
    let thingsChanged = false
    doc.docChanges().forEach(function (change) {
      if (change.type === 'added') {
        members.push({ id: change.doc.id, ...change.doc.data() })
      }

      if (change.type === 'modified') {
        const member = find(members, (member) => member.id === change.doc.id)
        const newMemberData = change.doc.data()
        if (member) {
          member.name = newMemberData.name
          member.vote = newMemberData.vote
        } else {
          console.error(`member is not found, so adding it.`)
          members.push({ id: change.doc.id, ...change.doc.data() })
        }
      }

      if (change.type === 'removed') {
        if (onMemberRemoved) {
          onMemberRemoved(change.doc.id)
        }
        remove(members, (member) => member.id === change.doc.id)
      }
      thingsChanged = true
      console.debug('member changes', {
        id: change.doc.id,
        ...change.doc.data(),
      })
    })
    session = { ...session, members }

    if (thingsChanged) transitionSessionStatus(sessionId, session)

    onSessionChange({ ...session, members })
  })

  const unRegister = () => {
    console.debug(`unregistered.`)
    onSession()
    onMembers()
  }

  return { session: { ...session, members }, unRegister }
}

export const removeUser = async (sessionId, memberId) => {
  return await db()
    .collection('sessions')
    .doc(sessionId)
    .collection('members')
    .doc(memberId)
    .delete()
}
const transitionSessionStatus = async (sessionId, session) => {
  if (session && (!session.members || session.members.length === 0)) {
    return
  }
  let statusToSet = null
  const votes = filter(session.members, (member) => member.vote)
  console.debug(
    `votes are ${votes.length}/${session.members.length}. current status is ${session.status}`
  )
  if (
    session.members &&
    votes &&
    votes.length === session.members.length &&
    (session.status === 'new' || session.status === 'in-progress')
  ) {
    statusToSet = 'completed'
  } else if (session.status === 'new' && votes && votes.length > 0) {
    statusToSet = 'in-progress'
  } else if (
    session.status === 'in-progress' &&
    votes &&
    session.members &&
    votes.length === session.members.length
  ) {
    statusToSet = 'completed'
  }

  if (statusToSet && session.status !== statusToSet) {
    console.debug(`will set new status to: ${statusToSet}`)
    const sessionRef = db().collection('sessions').doc(sessionId)

    await sessionRef.set(
      {
        status: statusToSet,
      },
      { merge: true }
    )
  }
}
export const getSessionIdById = async (id) => {
  const sessionsRef = db().collection('sessions')
  const query = await sessionsRef.where('id', '==', id).get()
  if (query.empty) {
    throw new Error("Can't find session.")
  }

  if (query.size === 1) {
    const doc = query.docs[0]
    return doc.id
  }
}

export const resetSession = async (sessionId, memberIds) => {
  const sessionRef = db().collection(`sessions`).doc(sessionId)

  await db().runTransaction(async (transaction) => {
    memberIds.forEach((id) => {
      const memberRef = db()
        .collection(`sessions`)
        .doc(sessionId)
        .collection('members')
        .doc(id)
      transaction.update(memberRef, {
        vote: null,
      })
    })

    return transaction.update(sessionRef, { status: 'new' })
  })
}
export const voteMember = async (sessionId, memberId, vote) => {
  const sessionRef = db().collection(`sessions`).doc(sessionId)
  const userRef = sessionRef.collection(`members`).doc(memberId)
  try {
    return await userRef.set({ vote: vote }, { merge: true })
  } catch (e) {
    throw new Error('User or session does not exist.')
  }
}
