import React, { useEffect } from 'react'
import { StartLayout } from '../components/start.layout'
import Icon, { ChatIcon, StartIcon } from '../components/linked.icon'
import { Link } from '@reach/router'
import { event } from '../analytics'

export const Start = () => {
  useEffect(() => {
    document.title = 'Welcome to Sprint Planner.'

    event('start')
  }, [])
  return (
    <StartLayout>
      <div className="flex flex-col w-full">
        <div
          style={{ fontFamily: `'Roboto Condensed', sans-serif` }}
          className="my-8 text-center text-2xl"
        >
          Welcome to the Sprint Planner App
        </div>
        <div className="block text-center">
          <Link to="/create">
            <Icon name="Create Session" icon={<StartIcon />} />
          </Link>
        </div>
        <div className="block text-center">
          <Link to="/join">
            <Icon name="Join Session" icon={<ChatIcon />} />
          </Link>
        </div>
      </div>
    </StartLayout>
  )
}
