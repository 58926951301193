import React from 'react'
import MemberView from './member.view'

export default ({ members, status, yourId = null }) => {
  return members && members.length ? (
    <div className="flex flex-row flex-wrap items-center justify-center leading-normal mx-auto">
      {members.map((value, key) => (
        <MemberView key={key} sessionState={status} member={value} />
      ))}
    </div>
  ) : (
    <div className="w-full xl:max-w-xl mx-auto flex flex-row flex-wrap justify-center leading-normal">
      Ah no members joined yet.
    </div>
  )
}
