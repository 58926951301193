import React from 'react'
import { MemberYetToVoteView, MemberVotedView } from './vote.choice'

export default ({ voteOptions, currentVote, onPick }) => {
  return (
    <div className="w-full mx-auto flex flex-row flex-wrap justify-center leading-normal">
      {voteOptions.map((v, i) =>
        v !== currentVote ? (
          <MemberYetToVoteView key={i} value={v} onPick={onPick} />
        ) : (
          <MemberVotedView key={i} value={v} />
        )
      )}
    </div>
  )
}
