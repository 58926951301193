import React from 'react'

export const MemberVotedView = ({ value }) => (
  <div className="border-gray-500 bg-gray-300 dark:border-gray-400 dark:bg-gray-500 cursor-not-allowed border rounded-lg mx-2 my-2 items-center p-4">
    {value}
  </div>
)

export const MemberYetToVoteView = ({ value, onPick }) => {
  const sendVote = (e, vote) => {
    e.preventDefault()
    onPick(vote)
  }
  return (
    <div
      className="border-gray-300 bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark-hover:bg-gray-600 hover:border-gray-700 hover:bg-pink-100 cursor-pointer border rounded-lg mx-2 my-2 items-center p-4"
      onClick={(e) => sendVote(e, value)}
    >
      <span>{value}</span>
    </div>
  )
}
