import React from 'react'
import { ThemeProvider } from './context/theme.context'
import { AppProvider } from './context/app.context'
import { Router } from '@reach/router'
import { Start } from './pages/start'
import { Join } from './pages/join'
import { Create } from './pages/create'
import { Error } from './pages/error'
import { Session } from './pages/session'
import { Votes } from './pages/votes'
import { About } from './pages/about'
import { ProvideAuth } from './context/useAuth'
import { SignIn } from './pages/signin'

const App = () => {
  return (
    <ProvideAuth>
      <AppProvider>
        <ThemeProvider>
          <Router>
            <Start path="/" />
            <Join path="join" />
            <Join path="join/:id" />
            <Create path="create" />
            <Session path=":id" />
            <Votes path=":id/:memberId" />
            <About path="about" />
            <Error default path="error" />
            <SignIn path="signin" />
          </Router>
        </ThemeProvider>
      </AppProvider>
    </ProvideAuth>
  )
}

export default App
