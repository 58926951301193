import React from 'react'
import Avatar from 'avataaars'
const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
]
const allTypes = {
  topType: [
    'ShortHairFrizzle',
    'Eyepatch',
    'Hat',
    'Hijab',
    'Turban',
    'WinterHat1',
    'WinterHat2',
    'WinterHat3',
    'WinterHat4',
    'LongHairBigHair',
    'LongHairBob',
    'LongHairBun',
    'LongHairCurly',
    'LongHairCurvy',
    'LongHairDreads',
    'LongHairFrida',
    'LongHairFro',
    'LongHairFroBand',
    'LongHairNotTooLong',
    'LongHairShavedSides',
    'LongHairMiaWallace',
    'LongHairStraight',
    'LongHairStraight2',
    'LongHairStraightStrand',
    'ShortHairDreads01',
    'ShortHairDreads02',
    'ShortHairFrizzle',
    'ShortHairShaggyMullet',
    'ShortHairShortCurly',
    'ShortHairShortFlat',
    'ShortHairShortRound',
    'ShortHairShortWaved',
    'ShortHairSides',
    'ShortHairTheCaesar',
    'ShortHairTheCaesarSidePart',
  ],
  accessoriesType: [
    'Blank',
    'Kurt',
    'Prescription01',
    'Prescription02',
    'Round',
    'Sunglasses',
    'Wayfarers',
  ],
  hairColor: [
    'Auburn',
    'Black',
    'Blonde',
    'BlondeGolden',
    'Brown',
    'BrownDark',
    'PastelPink',
    'Platinum',
    'Red',
    'SilverGray',
  ],
  clotheType: [
    'BlazerShirt',
    'BlazerSweater',
    'CollarSweater',
    'GraphicShirt',
    'Hoodie',
    'Overall',
    'ShirtCrewNeck',
    'ShirtScoopNeck',
    'ShirtVNeck',
  ],
  eyeType: [
    'Close',
    'Cry',
    'Default',
    'Dizzy',
    'EyeRoll',
    'Happy',
    'Hearts',
    'Side',
    'Squint',
    'Surprised',
    'Wink',
    'WinkWacky',
  ],
  eyebrowType: [
    'Angry',
    'AngryNatural',
    'Default',
    'DefaultNatural',
    'FlatNatural',
    'RaisedExcited',
    'RaisedExcitedNatural',
    'SadConcerned',
    'SadConcernedNatural',
    'UnibrowNatural',
    'UpDown',
    'UpDownNatural',
  ],

  mouthType: [
    'Concerned',
    'Default',
    'Disbelief',
    'Eating',
    'Grimace',
    'Sad',
    'ScreamOpen',
    'Serious',
    'Smile',
    'Tongue',
    'Twinkle',
    'Vomit',
  ],
}

const typeSizes = {
  topType: allTypes.topType.length,
  //   accessoriesType: allTypes.accessoriesType.length,
  hairColor: allTypes.hairColor.length,
  // facialHairType: allTypes.facialHairType.length,
  clotheType: allTypes.clotheType.length,
  eyeType: allTypes.eyeType.length,
  eyebrowType: allTypes.eyebrowType.length,
  mouthType: allTypes.mouthType.length,
  //skinColor: 7,
}

const nameToAvatarTypes = (name) => {
  let formattedName = (name || '').replace(/[\s]+/, '')
  formattedName = name.substring(
    0,
    Math.min(name.length, Object.keys(typeSizes).length)
  )
  return [...formattedName.toLowerCase()]
    .reverse()
    .map((l) => alphabet.indexOf(l))
    .map((k, i) => k % Object.values(typeSizes)[i])
    .map((v, i) => {
      return {
        [Object.keys(typeSizes)[i]]: allTypes[Object.keys(typeSizes)[i]][v],
      }
    })
    .reduce((prev, curr) => {
      return Object.assign({}, prev, curr)
    })
}

export const MemberImage = ({ name }) => {
  const options = nameToAvatarTypes(name)

  return (
    <Avatar
      className="member bg-gray-100 dark:bg-gray-700"
      style={{ width: 'inherit', height: 'inherit' }}
      avatarStyle="Circle"
      {...options}
    />
  )
}
