import React, { useState } from 'react'
import Icon, { StartIcon } from '../components/linked.icon'

export const CreateSessionView = ({ onCreateSession, defaultVoteTypes }) => {
  const [name, setName] = useState('')
  const [voteTypes, setVoteTypes] = useState(defaultVoteTypes)

  const submitSession = (e) => {
    e.preventDefault()
    onCreateSession(name, voteTypes)

    setName('')
    setVoteTypes(defaultVoteTypes)
  }

  return (
    <div className="flex flex-grow items-center">
      <div className="w-full max-w-sm xl:max-w-xl mx-auto">
        <form className="w-full">
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-2/6">
              <label
                className="block md:text-right mb-1 md:mb-0 pr-4"
                htmlFor="session-name"
              >
                Session Name
              </label>
            </div>
            <div className="md:w-4/6">
              <input
                className="bg-gray-100 dark:bg-gray-800 appearance-none border-b-2 border-gray-400 dark:border-gray-600  dark:text-gray-300 dark-focus:bg-gray-700  dark-focus:border-gray-600 w-full py-2 px-4 text-gray-700  leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                id="session-name"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                type="text"
                minLength="5"
                placeholder="Enter a meaningful session name."
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-2/6">
              <label
                className="block md:text-right mb-1 md:mb-0 pr-4"
                htmlFor="session-type"
              >
                Session Type
              </label>
            </div>
            <div className="md:w-4/6">
              <div className="flex-row items-center">
                <div>
                  <input
                    type="radio"
                    className="form-radio"
                    name="session-type"
                    onClick={(e) =>
                      setVoteTypes(['1', '2', '3', '5', '8', '13', '21', '∞'])
                    }
                    value="fib"
                    defaultChecked
                  />
                  <label className="ml-2" htmlFor="session-type">
                    Fibonacci Sequence{' '}
                    <span className="text-xs opacity-50">
                      (1, 2, 3, 5, 8, 13, 21, ∞)
                    </span>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    className="form-radio"
                    name="session-type"
                    onClick={(e) =>
                      setVoteTypes([
                        'small',
                        'medium',
                        'large',
                        'x-large',
                        'xx-large',
                      ])
                    }
                    value="tshirt"
                  />
                  <span className="ml-2">
                    T-Shirt Size{' '}
                    <span className="text-xs opacity-50">
                      (small, medium, large, x-large, xx-large)
                    </span>
                  </span>
                </div>
              </div>
              <div className="flex-row items-center">
                <div>
                  <input
                    type="radio"
                    className="form-radio"
                    name="session-type"
                    onClick={(e) =>
                      setVoteTypes([
                        '1h',
                        '2h',
                        '4h',
                        '1d',
                        '2d',
                        '3d',
                        '1w',
                        '2w',
                      ])
                    }
                    value="tshirt"
                  />
                  <span className="ml-2">
                    Time{' '}
                    <span className="text-xs opacity-50">
                      (1h, 2h, 4h, 1d, 2d, 3d, 1w, 2w)
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex md:items-center">
            <div className="md:w-1/3"></div>
            <div className="md:w-2/3">
              <Icon
                name="Create Sessoin"
                onClick={submitSession}
                icon={<StartIcon />}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
