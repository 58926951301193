import { navigate } from '@reach/router'
import React, { useState } from 'react'
import { event } from '../analytics'
import { removeUser, voteMember } from '../api'
import { Hello } from './hello'
import Icon, { HideIcon, ShowIcon } from './linked.icon'
import { Loading } from './loading'
import MembersView from './members.view'
import VoteChoices from './vote.choices'
import { VotingStatusActive, VotingStatusComplete } from './voting.status'

export function SessionVote({ sessionId, memberId, member, session }) {
  const [showMembers, setShowMembers] = useState(false)
  let start = new Date()

  const voteForItem = (item) => {
    if (session.status !== 'completed') {
      event('member_voted', {
        untilVote: new Date().getTime() - start.getTime(),
      })
      voteMember(sessionId, memberId, item)
    }
  }
  const removeMyself = (myId) =>
    removeUser(sessionId, myId).then(() => navigate('/'))

  if (!member) {
    return <Loading />
  } else
    return (
      <div className="h-full flex-grow">
        <div
          className="flex justify-center m-3 text-base sm:text-base md:text-xl lg:text-2xl xl:text-3xl capitalize"
          style={{ fontFamily: `'Roboto Condensed', sans-serif;"` }}
        >
          {session.name}
        </div>
        <div className="flex text-xs justify-center m-6">
          <div
            className="flex mb-2 select-none text-base overflow-visible"
            style={{ fontFamily: `'Space Mono', monospace` }}
          >
            <span className="px-2 py-2 capitalize">
              {' '}
              <Hello /> <span className="font-bold">{member.name}</span>:
            </span>
            <a
              href="#r"
              className="px-4 py-2 cursor-pointer underline hover:no-underline"
              onClick={async (e) => removeMyself(member.id)}
            >
              Exit
            </a>
          </div>
        </div>

        {session.status === 'completed' ? (
          <VotingStatusComplete />
        ) : (
          <VotingStatusActive />
        )}

        <VoteChoices
          currentVote={member.vote}
          onPick={async (v) => await voteForItem(v)}
          voteOptions={session.votingOptions}
        />

        <div className="text-center my-auto">
          <Icon
            onClick={(e) => {
              e.preventDefault()
              setShowMembers(!showMembers)
            }}
            name={showMembers ? 'Hide Members' : 'Show Members'}
            icon={showMembers ? <HideIcon /> : <ShowIcon />}
          />
          <div className={`opacity-75  ${showMembers ? 'visible' : 'hidden'}`}>
            {showMembers ? (
              <MembersView
                members={session.members}
                status={session.status}
                yourId={member.id}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    )
}
