import React from 'react'

export const HideIcon = () => (
  <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
    <g id="icon-shape">
      <path
        d="M16.6166796,6.21174757 C17.9442722,7.21287251 19.0378031,8.50785377 19.8005808,10 C17.9798698,13.5616759 14.2746855,16 10,16 C9.02896677,16 8.08732049,15.8741795 7.19047483,15.6379523 L8.96391361,13.8645135 C9.29436687,13.9528855 9.64168134,14 10,14 C12.209139,14 14,12.209139 14,10 C14,9.64168134 13.9528855,9.29436687 13.8645135,8.96391361 L16.6166796,6.21174757 Z M12.8095252,4.36204771 C11.9126795,4.12582046 10.9710332,4 10,4 C5.72531453,4 2.02013017,6.43832409 0.199419187,10 C0.962196907,11.4921462 2.05572777,12.7871275 3.38332044,13.7882524 L6.13548648,11.0360864 C6.04711451,10.7056331 6,10.3583187 6,10 C6,7.790861 7.790861,6 10,6 C10.3583187,6 10.7056331,6.04711451 11.0360864,6.13548648 L12.8095252,4.36204771 Z M16.363961,2.22182541 L17.7781746,3.63603897 L3.63603897,17.7781746 L2.22182541,16.363961 L16.363961,2.22182541 Z"
        id="Combined-Shape"
      ></path>
    </g>
  </g>
)

export const ShowIcon = () => (
  <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
    <g id="icon-shape">
      <path
        d="M19.8005808,10 C17.9798698,6.43832409 14.2746855,4 10,4 C5.72531453,4 2.02013017,6.43832409 0.199419187,10 C2.02013017,13.5616759 5.72531453,16 10,16 C14.2746855,16 17.9798698,13.5616759 19.8005808,10 Z M10,14 C12.209139,14 14,12.209139 14,10 C14,7.790861 12.209139,6 10,6 C7.790861,6 6,7.790861 6,10 C6,12.209139 7.790861,14 10,14 Z M10,12 C11.1045695,12 12,11.1045695 12,10 C12,8.8954305 11.1045695,8 10,8 C8.8954305,8 8,8.8954305 8,10 C8,11.1045695 8.8954305,12 10,12 Z"
        id="Combined-Shape"
      ></path>
    </g>
  </g>
)

export const BackIcon = () => (
  <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
    <g id="icon-shape">
      <polygon
        id="Combined-Shape"
        points="10 13 18 13 18 7 10 7 10 2 2 10 10 18 10 13"
      ></polygon>
    </g>
  </g>
)

export const UserIcon = () => (
  <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
    <g id="icon-shape">
      <path
        d="M10,20 C15.5228475,20 20,15.5228475 20,10 C20,4.4771525 15.5228475,0 10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 Z M6.99999861,6.00166547 C6.99999861,4.34389141 8.3465151,3 9.99999722,3 C11.6568507,3 12.9999958,4.33902013 12.9999958,6.00166547 L12.9999958,7.99833453 C12.9999958,9.65610859 11.6534793,11 9.99999722,11 C8.34314374,11 6.99999861,9.66097987 6.99999861,7.99833453 L6.99999861,6.00166547 Z M3.34715433,14.4444439 C5.37306718,13.5169611 7.62616222,13 10,13 C12.3738388,13 14.6269347,13.5169615 16.6528458,14.4444437 C15.2177146,16.5884188 12.7737035,18 10,18 C7.22629656,18 4.78228556,16.5884189 3.34715433,14.4444439 L3.34715433,14.4444439 Z"
        id="Combined-Shape"
      ></path>
    </g>
  </g>
)

export const ChatIcon = () => (
  <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
    <g id="icon-shape">
      <path
        d="M10,15 L18.0092049,15 C19.1017876,15 20,14.1019465 20,12.9941413 L20,3.00585866 C20,1.89706013 19.1086907,1 18.0092049,1 L1.99079514,1 C0.898212381,1 0,1.89805351 0,3.00585866 L0,12.9941413 C0,14.1029399 0.891309342,15 1.99079514,15 L6,15 L6,19 L10,15 Z M5,7 L7,7 L7,9 L5,9 L5,7 Z M9,7 L11,7 L11,9 L9,9 L9,7 Z M13,7 L15,7 L15,9 L13,9 L13,7 Z"
        id="Combined-Shape"
      ></path>
    </g>
  </g>
)

export const StartIcon = () => (
  <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
    <g id="icon-shape">
      <path
        d="M2.92893219,17.0710678 C6.83417511,20.9763107 13.1658249,20.9763107 17.0710678,17.0710678 C20.9763107,13.1658249 20.9763107,6.83417511 17.0710678,2.92893219 C13.1658249,-0.976310729 6.83417511,-0.976310729 2.92893219,2.92893219 C-0.976310729,6.83417511 -0.976310729,13.1658249 2.92893219,17.0710678 L2.92893219,17.0710678 Z M15.6568542,15.6568542 C18.7810486,12.5326599 18.7810486,7.46734008 15.6568542,4.34314575 C12.5326599,1.21895142 7.46734008,1.21895142 4.34314575,4.34314575 C1.21895142,7.46734008 1.21895142,12.5326599 4.34314575,15.6568542 C7.46734008,18.7810486 12.5326599,18.7810486 15.6568542,15.6568542 Z M7,6 L15,10 L7,14 L7,6 Z"
        id="Combined-Shape"
      ></path>
    </g>
  </g>
)

export default ({ name, onClick = () => {}, icon = <StartIcon /> }) => {
  const _onClick = (e) => {
    onClick(e)
  }

  return (
    <span className="py-1 inline-flex items-center">
      <svg
        className="fill-current text-gray-900 dark:text-gray-100 inline-block w-4 h-4 mr-2"
        viewBox="0 0 20 20"
        version="1.1"
      >
        {icon}
      </svg>

      <span
        className="cursor-pointer py-1 underline hover:no-underline"
        onClick={_onClick}
      >
        {name}
      </span>
    </span>
  )
}
