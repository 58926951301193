import React from 'react'
import { Activity, Check } from 'react-feather'

export const VotingStatusActive = () => (
  <div className="w-full max-w-sm xl:max-w-xl mx-auto flex flex-row flex-wrap justify-center text-sm  mb-4">
    <div
      style={{ fontFamily: `'Space Mono', monospace` }}
      className="text-green-600 font-semibold py-2"
    >
      <Activity />
    </div>
    <div className="text-base px-2 py-2 text-green-600">Active</div>
    <div className="text-base px-2 py-2">
      Voting in progress. You can vote now.
    </div>
  </div>
)

export const VotingStatusComplete = () => (
  <div className="w-full mx-auto flex flex-row flex-wrap justify-center text-s mb-4">
    <div
      style={{ fontFamily: `'Space Mono', monospace` }}
      className="text-gray-600 font-semibold py-2"
    >
      <Check />
    </div>
    <div className="text-base px-2 py-2 text-gray-600">Completed</div>
    <div className="text-base px-2 py-2">
      Voting is complete. Wait until the voting is reset.
    </div>
  </div>
)
