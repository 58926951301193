import * as firebase from 'firebase/app'
import 'firebase/analytics'
import { firebaseConfig } from './firebaseconfig'

if (!firebase.apps || firebase.apps.length === 0)
  firebase.initializeApp(firebaseConfig)
const analytics = firebase.analytics()

export const event = (name, data) => {
  return new Promise((resolve, reject) => {
    try {
      analytics.logEvent(name, data)
      resolve()
    } catch (e) {
      reject("can't log event, something failed. ignoring...")
    }
  })
}
