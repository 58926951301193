import React, { useEffect, useState } from 'react'
import spinner from '../styles/pulse.svg'

const SimpleSpinner = () => (
  <div className="absolute top-0 right-0">
    <img alt="Loading ≥≥≥≥≥≥" className="w-8 l-8" src={spinner} />
  </div>
)
const WaitingTooLongSpiner = () => (
  <div className="flex flex-grow items-center justify-center w-full mx-auto h-full">
    <img alt="Loading ≥≥≥≥≥" className="w-8 l-8" src={spinner} /> Oh it's taking
    too long.
  </div>
)
export const Loading = () => {
  const [type, setType] = useState('simple')
  let timer = setTimeout(() => setType('not-simple'), 3000)

  useEffect(() => {
    return () => (timer ? clearTimeout(timer) : 1 + 1)
  })

  return type === 'simple' ? <SimpleSpinner /> : <WaitingTooLongSpiner />
}
