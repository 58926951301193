import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { AppContext } from '../context/app.context'
import { AlertCircle } from 'react-feather'

export const AppMessageRenderer = () => {
  const { message, setMessage } = useContext(AppContext)
  const [pctComplete, setPctComplete] = useState(0)

  useEffect(() => {
    setPctComplete(0)
    const interval = setInterval(() => {
      setPctComplete((k) => k + 1)
    }, 100)
    return () => clearInterval(interval)
  }, [message])

  if (message) {
    return (
      <div className="absolute inset-x-0 top-0 text-center">
        <div
          className="dark:bg-gray-600 bg-gray-300 h-1 rounded-full mb-1"
          style={{ width: pctComplete + '%' }}
        ></div>

        <div
          onClick={(e) => {
            e.preventDefault()
            setMessage('')
          }}
          className="cursor-pointer p-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 items-center leading-none lg:rounded-full flex lg:inline-flex"
          role="alert"
        >
          <span className="flex rounded-full bg-gray-400 dark:bg-gray-800 uppercase px-1 py-1 text-xs font-bold mr-3">
            <AlertCircle className="stroke-current opacity-75 h-6 w-6" />
          </span>
          <span className="font-semibold mr-2 text-left flex-auto">
            {message}
          </span>
        </div>
      </div>
    )
  }
  return <span></span>
}
