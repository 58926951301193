import React from "react";
import { Layout } from "../components/layout";
import { navigate } from "@reach/router";
import Icon, {BackIcon} from "../components/linked.icon";

export const Error = ({ state }) => (
  <Layout>
    <div className="flex flex-grow items-center">
      <div className="w-full max-w-sm xl:max-w-xl mx-auto">
        <div className="text-xl my-2">Oh Snap. Errored.</div>
        {state && state.message ? <div className="m-4">{state.message}</div> : ""}
        <div className=" my-2">
            <Icon name="Go Back" icon={<BackIcon />} onClick={e => navigate(-1)} />
        </div>
      </div>
    </div>
  </Layout>
);
