import React, { useEffect } from 'react'
// import  licenses from "../licenses.json";

import { Layout } from '../components/layout'
import { event } from '../analytics'
// let licenses = require("../licenses.csv");

export const About = () => {
  useEffect(() => {
    document.title = `About - Sprint Planner`
    event('about')
  }, [])
  return (
    <Layout>
      <div className="flex flex-grow items-center w-full max-w-xl xl:max-w-xl mx-auto">
        <div className="block w-full mx-auto my-auto">
          <h2 className="text-xl">Created by @afrimdev</h2>
          {/* 
          <p className="m-5">
           <span className="text-xxl m-2" role="img" aria-label="love for open source">💖</span>Thanks to the opensource community. <a className="underline" href={licenses}>See Licenses</a>
          </p> */}
        </div>
      </div>
    </Layout>
  )
}
