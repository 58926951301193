import React, { useContext } from 'react'
import { ThemeContext } from '../context/theme.context'
import { Sun, Moon } from 'react-feather'

export const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useContext(ThemeContext)
  const icon = theme === 'mode-dark' ? <Sun /> : <Moon />
  return (
    <div
      onClick={toggleTheme}
      className="cursor-pointer rounded-t-lg bg-gray-800 text-gray-200 mx-1 p-2 dark:text-gray-800 dark:bg-gray-200 "
    >
      {icon}
    </div>
  )
}
