import React, { useState } from 'react'
import Icon, { HideIcon, ShowIcon } from './linked.icon'
import pad from '@fav/text.pad'

export default ({ members, onRemoveMember }) => {
  const [showRemoveMember, setShowRemoveMember] = useState(false)
  const onRemove = (e) => {
    e.preventDefault()
    setShowRemoveMember(!showRemoveMember)
  }
  const removeMembersLinks = members.map((member) => (
    <SingleMemberRemove
      key={member.id}
      memberId={member.id}
      member={member}
      onRemoveMember={onRemoveMember}
    />
  ))
  return (
    <div className="w-full max-w-sm mx-auto flex flex-col flex-wrap items-center justify-center my-4 text-sm">
      <div style={{ fontFamily: `'Space Mono', monospace"` }}>
        <Icon
          onClick={onRemove}
          name={showRemoveMember ? 'Hide It Now' : 'Remove Someone'}
          icon={showRemoveMember ? <HideIcon /> : <ShowIcon />}
        />
        {/* <Icon name="" onClick={onRemove} icon={<UserIcon />}/> */}
        {/* <a
          onClick={(e) => setShowRemoveMember(!showRemoveMember)}
          className="px-4 py-2 cursor-pointer underline hover:no-underline">
          Remove Someone?
        </a> */}
      </div>
      <div
        className={`flex flex-row flex-wrap ${
          showRemoveMember ? 'block' : 'hidden'
        }`}
      >
        {removeMembersLinks ? (
          removeMembersLinks
        ) : (
          <div>You really clicked this even when there's no members?!</div>
        )}
      </div>
    </div>
  )
}

const SingleMemberRemove = ({ memberId, member, onRemoveMember }) => {
  return (
    <button
      className="my-1 mx-1 p-1 text-gray-700 bg-gray-200 dark:text-gray-200 dark:bg-gray-700 inline-flex items-center rounded-lg"
      onClick={(e) => onRemoveMember(memberId)}
    >
      <div className="hover:text-red-700 ">
        <svg
          className="fill-current w-6 h-6 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          id="Layer"
          enableBackground="new 0 0 64 64"
          height="512"
          viewBox="0 0 64 64"
          width="512"
        >
          <path d="m32 8c-13.234 0-24 10.766-24 24s10.766 24 24 24 24-10.766 24-24-10.766-24-24-24zm0 44c-11.028 0-20-8.972-20-20s8.972-20 20-20 20 8.972 20 20-8.972 20-20 20z" />
          <path d="m39.414 24.586c-.78-.781-2.048-.781-2.828 0l-4.586 4.586-4.586-4.586c-.78-.781-2.048-.781-2.828 0-.781.781-.781 2.047 0 2.828l4.586 4.586-4.586 4.586c-.781.781-.781 2.047 0 2.828.39.391.902.586 1.414.586s1.024-.195 1.414-.586l4.586-4.586 4.586 4.586c.39.391.902.586 1.414.586s1.024-.195 1.414-.586c.781-.781.781-2.047 0-2.828l-4.586-4.586 4.586-4.586c.781-.781.781-2.047 0-2.828z" />
        </svg>
      </div>
      <span className="hover:text-black dark-hover:text-white cursor-default">
        {pad(member.name, 6, `\u007f`)}
      </span>
    </button>
  )
}
