import React, { createContext, useState } from 'react'

export const ThemeContext = createContext({})
const getOtherThemeName = (thiz) =>
  thiz === 'mode-white' ? 'mode-dark' : 'mode-white'

export const ThemeProvider = (props) => {
  const preferredTheme = window?.matchMedia('(prefers-color-scheme: dark)')
    ?.matches
    ? 'mode-dark'
    : 'mode-white'
  const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
  darkModeMediaQuery.addListener((e) => {
    const darkModeOn = e.matches
    const themeAlreadySet = window?.localStorage?.getItem('votes-theme')
    console.debug(`Dark mode is ${darkModeOn ? '🌒 on' : '☀️ off'}.`)
    if (darkModeOn && !themeAlreadySet) {
      setTheme('mode-dark')
    } else if (!darkModeOn && !themeAlreadySet) {
      setTheme('mode-white')
    }
  })
  const [theme, setTheme] = useState(
    window?.localStorage.getItem('votes-theme') || preferredTheme
  )

  const toggleTheme = () => {
    const themeToSet = getOtherThemeName(theme)
    setTheme(themeToSet)
    if (window.localStorage) {
      window.localStorage.setItem('votes-theme', themeToSet)
    }
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {props.children}
    </ThemeContext.Provider>
  )
}
