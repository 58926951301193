import pad from '@fav/text.pad'
import React from 'react'

import { MemberImage } from './member.image'

const VALUE_WHEN_PENDING = <span>?</span>
const VALUE_WHEN_COMPLETED = <span>&#x2713;</span>

const MemberValueShown = ({ vote }) => {
  return <span>{vote}</span>
}

const MemberValuePending = () => {
  return VALUE_WHEN_PENDING
}

const MemberValueCompleted = () => {
  return VALUE_WHEN_COMPLETED
}

const MemberValue = ({ vote, state }) => {
  if (state === 'new') {
    return <MemberValuePending />
  } else if (state !== 'completed' && vote) {
    return <MemberValueCompleted />
  } else if (state !== 'completed' && !vote) {
    return <MemberValuePending />
  } else {
    return <MemberValueShown vote={vote} />
  }
}

export default ({ member, sessionState, maximumSizeReference }) => {
  return (
    <div className="flex flex-row border-gray-300 bg-gray-100 dark:bg-gray-800 dark:border-gray-800 border rounded-lg mx-1 my-1 items-center h-12 sm:h-12 md:h-16 xl:h-16 lg:h-16">
      <MemberImage
        className="flex-none object-contain object-cover rounded-lg h-10 mx-2 opacity-75 bg-green-300"
        name={member.name}
      />
      <div className="flex flex-col flex-auto h-14 mr-2 py-2">
        <div className="tracking-wider text-center xs:text-xs sm:text-sm md:text-md lg:text-lg text-gray-700 dark:text-gray-300">
          {pad(member.name, 6, `\u007f`)}
          {/* {member.name} */}
        </div>
        <div
          className="inline-block text-center items-center font-thin"
          style={{ fontFamily: `Oswald', sans-serif` }}
        >
          <MemberValue vote={member.vote} state={sessionState} />
        </div>
      </div>
    </div>
  )
}
