import React from 'react'
import { Layout } from './layout'

export const StartLayout = (props) => {
  return (
    <Layout>
      <div className="overflow-visible flex flex-grow items-center w-full max-w-xl xl:max-w-xl mx-auto">
        {props.children}
      </div>
    </Layout>
  )
}
