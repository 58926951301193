import React, { useEffect, useState, useContext } from 'react'
import { StartLayout } from '../components/start.layout'
import { SessionPresenter } from '../components/session.presenter'
import { withSession, removeUser, resetSession } from '../api'
import { event } from '../analytics'
import { AppContext } from '../context/app.context'

export const Session = ({ id }) => {
  const { setMessage } = useContext(AppContext)
  const [session, setSession] = useState({ id: '' })
  let unRegister
  let start = new Date()
  useEffect(() => {
    document.title = `Session - Sprint Planner`
    event('session_start')

    withSession(id, (session) => {
      setSession(session)
    })
      .then((data) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        start = new Date()
        setSession(data.session)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        unRegister = data.unRegister
      })
      .catch((e) => console.error(`Can't load session.`, e))

    return function cleanup() {
      if (unRegister) {
        unRegister()
        unRegister = null
      }
    }
  }, [id])

  const rm = async (memberId) => {
    event('session_remove_user', {
      since: new Date().getTime() - start.getTime(),
    })
    await removeUser(session.sessionId, memberId)
  }

  const reset = async () => {
    resetSession(
      session.sessionId,
      session.members.map((m) => m.id)
    ).catch((e) => setMessage(e.message))
  }
  return (
    <StartLayout>
      <SessionPresenter
        session={session}
        onRemoveMember={rm}
        resetAllVotes={reset}
      />
    </StartLayout>
  )
}
