import React, { useState, useEffect, useContext } from 'react'
import { StartLayout } from '../components/start.layout'
import Icon, { ChatIcon } from '../components/linked.icon'
import { navigate } from '@reach/router'
import { addMember, getSessionIdById } from '../api'
import { AppContext } from '../context/app.context'
import { event } from '../analytics'
import { useAuth } from '../context/useAuth'

const sessionExample = {
  id: 'xyz',
  name: 'Some session goes here.',
  members: {
    a: { name: 'Afrim', vote: '1' },
    b: { name: 'James', vote: '1' },
  },
  status: 'new',
  votingOptions: {
    1: '1',
    2: '2',
    3: '3',
    4: '5',
    5: '8',
  },
  selectedMember: { name: '....loading', vote: '0' },
}

export const Join = ({ id }) => {
  const app = useContext(AppContext)
  const [sessionCode, setSessionCode] = useState(id || '')
  const [isJoinEnabled, setJoinButtonStatus] = useState(true)
  const [firstName, setFirstName] = useState('')
  const { user, signinAnonymously } = useAuth()

  useEffect(() => {
    if (!user) {
      signinAnonymously().then((user) => console.log(user))
    }
    document.title = `Join A Session - Sprint Planner`
    event('page_join')
  }, [user, signinAnonymously])
  const joinSession = async (e) => {
    setTimeout(() => setJoinButtonStatus(true), 1000)
    e.preventDefault()
    setJoinButtonStatus(false)

    try {
      if (!sessionCode || !firstName) {
        event('join_failed')
        throw Error('All fields are required.')
      }

      event('join', { sessionCode: sessionCode, firstName: firstName })

      const sessionId = await getSessionIdById(sessionCode)
      const memberId = await addMember(sessionId, {
        name: firstName,
        vote: null,
        userId: user && user.uid ? user.uid : null,
      })
      navigate(`/${sessionId}/${memberId}`, {
        state: { session: sessionExample },
      })
    } catch (e) {
      event('join_error', {
        sessionCode: sessionCode,
        firstName: firstName,
        message: e.message,
      })

      setJoinButtonStatus(true)
      app.setMessage(`${e.message}`)
    }
  }

  return (
    <StartLayout>
      <div className="flex flex-grow items-center">
        <div className="w-full max-w-sm xl:max-w-xl mx-auto">
          <form className="w-full">
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-2/6">
                <label
                  className="block md:text-right mb-1 md:mb-0 pr-4"
                  htmlFor="session-code"
                >
                  Session Code
                </label>
              </div>
              <div className="md:w-4/6">
                {/* <input
                                        className="bg-gray-100 appearance-none py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                                        id="session-code" type="text" value={sessionCode} onChange={e => setSessionCode(e.currentTarget.value)} /> */}
                <input
                  className="bg-gray-100 dark:bg-gray-800 appearance-none border-b-2 border-gray-400 dark:border-gray-600 w-full py-2 px-4 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:bg-white dark-focus:bg-gray-700 focus:border-gray-300 dark-focus:border-gray-600"
                  id="session-code"
                  type="text"
                  placeholder="Enter an existing session code."
                  value={sessionCode}
                  required="required"
                  onChange={(e) => setSessionCode(e.currentTarget.value)}
                />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-2/6">
                <label
                  className="block md:text-right mb-1 md:mb-0 pr-4"
                  htmlFor="first-name"
                >
                  First Name
                </label>
              </div>
              <div className="md:w-4/6">
                <input
                  className="bg-gray-100 dark:bg-gray-800 appearance-none border-b-2 border-gray-400 dark:border-gray-600  dark:text-gray-300 dark-focus:bg-gray-700  dark-focus:border-gray-600 w-full py-2 px-4 text-gray-700  leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                  id="first-name"
                  type="text"
                  value={firstName}
                  required="required"
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                  placeholder="Enter your first name only."
                />
              </div>
            </div>
            <div className="md:flex md:items-center">
              <div className="md:w-1/3"></div>
              <div className="md:w-2/3">
                <Icon
                  onClick={joinSession}
                  disabled={isJoinEnabled}
                  name="Join"
                  icon={<ChatIcon />}
                />

                {/* <a className="cursor-pointer py-1 underline hover:no-underline" onClick={joinSession}>Join</a> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </StartLayout>
  )
}
