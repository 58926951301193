import React, { createContext, useState } from 'react'

export const AppContext = createContext({})

export const AppProvider = (props) => {
  const [message, _setMessage] = useState('')
  let timeoutId
  const setMessage = (message) => {
    _setMessage(message)
    timeoutId = setTimeout(() => {
      _setMessage(null)
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
      }
    }, 10000)
  }
  return (
    <AppContext.Provider value={{ message, setMessage }}>
      {props.children}
    </AppContext.Provider>
  )
}
