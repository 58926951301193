import React, { useState, useContext } from 'react'
import { StartLayout } from '../components/start.layout'
import { useAuth } from '../context/useAuth'
import { AppContext } from '../context/app.context'
import Button, { StartIcon } from '../components/linked.icon'

export const SignIn = () => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [password2, setPassword2] = useState()

  const { signup } = useAuth()
  const { setMessage } = useContext(AppContext)

  const onSignUp = async () => {
    if (!email || !password || password !== password2) {
      setMessage(
        `You must provide an email and password (second password much match!)`
      )
      return
    }

    try {
      const user = await signup(email, password)
      console.log(user)
    } catch (e) {
      setMessage(e.message)
    }
  }
  return (
    <StartLayout>
      <div className="flex content-center justify-center flex-wrap w-full">
        <form className="w-full max-w-sm items-center mx-2">
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3"></div>
            <div
              style={{ fontFamily: `'Roboto Condensed', sans-serif` }}
              className="md:w-2/3 text-center text-2xl border-b-2 border-pink-100"
            >
              Register
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block md:text-right mb-1 md:mb-0 pr-4"
                forHtml="inline-email"
              >
                E-Mail
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                className="bg-gray-100 dark:bg-gray-800 appearance-none border-b-2 border-gray-400 dark:border-gray-600  dark:text-gray-300 dark-focus:bg-gray-700  dark-focus:border-gray-600 w-full py-2 px-4 text-gray-700  leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                id="session-name"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                type="text"
                minLength="5"
                placeholder="Enter an email address."
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block md:text-right mb-1 md:mb-0 pr-4"
                forHtml="inline-password"
              >
                Password
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                onChange={(e) => setPassword(e.currentTarget.value)}
                className="bg-gray-100 dark:bg-gray-800 appearance-none border-b-2 border-gray-400 dark:border-gray-600  dark:text-gray-300 dark-focus:bg-gray-700  dark-focus:border-gray-600 w-full py-2 px-4 text-gray-700  leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                id="inline-password"
                type="password"
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block md:text-right mb-1 md:mb-0 pr-4"
                forHtml="inline-password2"
              >
                Confirm Password
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                onChange={(e) => setPassword2(e.currentTarget.value)}
                className="bg-gray-100 dark:bg-gray-800 appearance-none border-b-2 border-gray-400 dark:border-gray-600  dark:text-gray-300 dark-focus:bg-gray-700  dark-focus:border-gray-600 w-full py-2 px-4 text-gray-700  leading-tight focus:outline-none focus:bg-white focus:border-gray-300"
                id="inline-password2"
                type="password"
              />
            </div>
          </div>
          <div className="md:flex md:items-center">
            <div className="md:w-1/3"></div>
            <div className="md:w-2/3">
              <Button onClick={onSignUp} name="Sign Up" icon={<StartIcon />} />
            </div>
          </div>
        </form>
      </div>
    </StartLayout>
  )
}
